// React / js stuff
import { EventEmitter } from 'events';

// Custom enum for active app
import { APPS, SCENARIO_EVENTS } from './enums';
import { initialLoadScenario, mapsRerouteScenario, musicDownloadScenario, entireScenario, otaTonightScenario } from './Scenarios';
import ScenarioRunner from './ScenarioRunner';
import ScenarioEvent from '../model/ScenarioEvent';

// Typescript doesn't like import for sound files?
const ding = require('../assets/sound/ding.mp3');
const mapsToast = require('../assets/sound/maps-toast.wav');
const musicToast = require('../assets/sound/music-toast.wav');

export const AppEventTypes = {
    appState: 'appState',
    scenarioEvent: 'scenarioEvent'
};

export default class AppController {
    eventEmitter: EventEmitter;
    appState: {
        activeApp: string;
        appSwitcherActive: boolean;
        currentScenarioEvent?: ScenarioEvent;
        scenarioIsRunning: boolean;
        totalAutoplayDuration?: number;
        isFullscreen: boolean;
        isMuted: boolean;
        audio: any;
    };
    scenarioRunner: ScenarioRunner;
    scenarioEvent: any;
    constructor() {
        this.eventEmitter = new EventEmitter();
        this.eventEmitter.setMaxListeners(100);
        this.appState = {
            activeApp: APPS.MAPS,
            appSwitcherActive: true,
            currentScenarioEvent: initialLoadScenario[0],
            scenarioIsRunning: false,
            totalAutoplayDuration: undefined,
            isFullscreen: false,
            isMuted: true,
            audio: new Audio(ding)
        };
        this.scenarioRunner = new ScenarioRunner();
        this.scenarioRunner.setEventQueue(mapsRerouteScenario); // TODO: change to default scenario
    }

    // Setter function for active app
    // TODO: Should be an enum for the parameter
    setActiveApp(activeApp: string, shouldInterrupScenario: boolean) {
        if (shouldInterrupScenario) {
            // Cancel active scenario
            this.stopScenarioRunner();
        }

        // Only take values from app enum
        // Otherwise throw error
        switch (activeApp) {
            case APPS.MUSIC:
                this.appState.activeApp = activeApp;
                if (shouldInterrupScenario) {
                    this.scenarioRunner.setEventQueue(musicDownloadScenario);
                    this.setScenarioEvent(musicDownloadScenario[0]);
                }
                break;
            case APPS.MAPS:
                this.appState.activeApp = activeApp;
                if (shouldInterrupScenario) {
                    this.scenarioRunner.setEventQueue(mapsRerouteScenario);
                    this.setScenarioEvent(mapsRerouteScenario[0]);
                }
                break;
            case APPS.OTA:
                this.appState.activeApp = activeApp;
                if (shouldInterrupScenario) {
                    this.scenarioRunner.setEventQueue(otaTonightScenario);
                    this.setScenarioEvent(otaTonightScenario[0]);
                }
                break;
            case APPS.NONE:
                this.appState.activeApp = activeApp;
                break;
            default:
                throw new Error('Tried setting active app with invalid value! Try using app enum from appEnums.js.');
        }

        // Broadcast global app state change
        this.eventEmitter.emit(AppEventTypes.appState, this.appState);
    }

    setAppSwitcherActive(appSwitcherActive: boolean) {
        // Only allow boolean values
        // Feels a little silly to use a switch for this assertion, but it works...
        switch (appSwitcherActive) {
            case true:
                this.appState.appSwitcherActive = true;
                break;
            case false:
                this.appState.appSwitcherActive = false;
                break;
            default:
                throw new Error('Tried setting appSwitcherActive with value other than boolean: "' + appSwitcherActive + '"');
        }

        // Broadcast global app state change
        this.eventEmitter.emit(AppEventTypes.appState, this.appState);
    }

    setIsFullscreen(isFullscreen: boolean) {
        this.appState.isFullscreen = isFullscreen;
        this.eventEmitter.emit(AppEventTypes.appState, this.appState);
    }

    setMute(isMuted: boolean) {
        if (!isMuted) {
            this.appState.audio = new Audio(ding);
            this.appState.audio.play();
        }

        this.appState.isMuted = isMuted;
        this.eventEmitter.emit(AppEventTypes.appState, this.appState);
    }

    // Broadcast a scenario event to any listeners
    // Is called from buttons in presentation container to trigger scenario events
    // Later through demo UI and possibly "hidden" buttons
    broadcastScenarioEvent(scenarioEvent: string) {
        switch (scenarioEvent) {
            case SCENARIO_EVENTS.MAPS_REROUTE_TOAST:
                this.eventEmitter.emit(AppEventTypes.scenarioEvent, SCENARIO_EVENTS.MAPS_REROUTE_TOAST);
                break;
            default:
                throw new Error("Tried broadcasting event which doesn't exist!");
        }
    }

    startScenarioRunner(eventQueue: ScenarioEvent[]) {
        this.appState.totalAutoplayDuration = eventQueue.reduce((accumulator, event) => {
            return accumulator + event.duration;
        }, 0);
        this.scenarioRunner.setEventQueue(eventQueue);
        this.scenarioRunner.startScenario();
    }

    stopScenarioRunner() {
        this.scenarioRunner.stopScenario();
    }

    public getScenerioIsRunning(): boolean {
        return this.scenarioRunner.getIsRunning();
    }

    // For setting the current scenario event
    setScenarioEvent(event: ScenarioEvent) {
        if (event) {
            switch (event.eventType) {
                case SCENARIO_EVENTS.TRIGGER_VIEW_CHANGE:
                    this.setActiveApp(event.activeView, false);
                    break;
                case SCENARIO_EVENTS.MAPS_INITIAL:
                    break;
                case SCENARIO_EVENTS.MAPS_REROUTE_TOAST:
                    if (!this.appState.isMuted) {
                        this.appState.audio = new Audio(mapsToast);
                        this.appState.audio.play();
                    }
                    break;
                case SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE:
                    break;
                case SCENARIO_EVENTS.MAPS_REROUTED:
                    break;
                case SCENARIO_EVENTS.MUSIC_INITIAL:
                    break;
                case SCENARIO_EVENTS.MUSIC_DOWNLOAD_TOAST:
                    if (!this.appState.isMuted) {
                        this.appState.audio = new Audio(musicToast);
                        this.appState.audio.play();
                    }
                    break;
                case SCENARIO_EVENTS.MUSIC_DOWNLOADING:
                    break;
                case SCENARIO_EVENTS.MUSIC_DOWNLOADED:
                    break;
                case SCENARIO_EVENTS.OTA_INITIAL:
                    break;
                case SCENARIO_EVENTS.OTA_INSTALL_NOW:
                    break;
                case SCENARIO_EVENTS.OTA_SET_TIME:
                    break;
                case SCENARIO_EVENTS.OTA_SCHEDULED_FINAL:
                    break;
                case SCENARIO_EVENTS.OTA_FINAL:
                    break;
                case SCENARIO_EVENTS.LOOP_ENTIRE_AUTOPLAY:
                    // Need to set a timeout here because otherwise progress bar in PresentationContainer doesn't reset?
                    // It resets on scenarioIsRunning === false
                    // FEELS like the change doesn't progate on time (but it does, console.log's show)
                    // So seems like Framer motion has a throttle or debounce built in?
                    const timer = setTimeout(() => {
                        this.startScenarioRunner(entireScenario);
                        clearTimeout(timer);
                    }, 1500);
                    break;
                default:
                    throw new Error('Tried setting event with invalid event ' + event);
            }
        }
        this.appState.currentScenarioEvent = event;
        this.eventEmitter.emit(AppEventTypes.appState, this.appState);
    }

    setScenarioIsRunning(isRunning: boolean) {
        this.appState.scenarioIsRunning = isRunning;
        this.eventEmitter.emit(AppEventTypes.appState, this.appState);
    }

    getCurrentScenarioEvent() {
        return this.scenarioEvent;
    }

    getAppState() {
        return this.appState;
    }

    addListener(eventType: string, callback: () => void) {
        this.eventEmitter.addListener(eventType, callback);
    }

    removeListener(eventType: string, callback: () => void) {
        this.eventEmitter.removeListener(eventType, callback);
    }
}

export let AppControllerInstance = new AppController();
