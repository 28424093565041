import ScenarioEvent from '../model/ScenarioEvent';
import { APPS, SCENARIO_EVENTS } from './enums';

export const initialLoadScenario: ScenarioEvent[] = [
    {
        description: 'Initial state of maps scenario',
        duration: 5000,
        activeView: '',
        eventType: SCENARIO_EVENTS.INITIAL_LOAD
    }
];
export const mapsRerouteScenario: ScenarioEvent[] = [
    {
        description: 'Initial state of maps scenario',
        duration: 5000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MAPS_INITIAL
    },
    {
        description: 'Showing reroute suggestion toast in the map view',
        duration: 7000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MAPS_REROUTE_TOAST
    },
    {
        description: 'Reroute has been chosen, filling progress bar',
        duration: 4000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MAPS_CHOOSING_REROUTE
    },
    {
        description: 'Rerouted Maps',
        duration: 5000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MAPS_REROUTED
    }
];

export const musicDownloadScenario: ScenarioEvent[] = [
    {
        description: 'Initial state of music scenario',
        duration: 5000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MUSIC_INITIAL
    },
    {
        description: 'Showing download suggestion toast in the music view',
        duration: 7000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MUSIC_DOWNLOAD_TOAST
    },
    {
        description: 'Download has been chosen, filling progress bar',
        duration: 10000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MUSIC_DOWNLOADING
    },
    {
        description: 'Downloaded music',
        duration: 5000,
        activeView: '',
        eventType: SCENARIO_EVENTS.MUSIC_DOWNLOADED
    }
];

export const otaTonightScenario: ScenarioEvent[] = [
    {
        description: 'Ota initial',
        duration: 5000,
        activeView: '',
        eventType: SCENARIO_EVENTS.OTA_INITIAL
    },
    {
        description: 'Ota set time',
        duration: 7000,
        activeView: '',
        eventType: SCENARIO_EVENTS.OTA_SET_TIME
    },
    {
        description: 'Ota Scehduled Final',
        duration: 7000,
        activeView: '',
        eventType: SCENARIO_EVENTS.OTA_SCHEDULED_FINAL
    }
];

export const otaNowScenario: ScenarioEvent[] = [
    {
        description: 'Ota initial',
        duration: 5000,
        activeView: '',
        eventType: SCENARIO_EVENTS.OTA_INITIAL
    },
    {
        description: 'Ota install now',
        duration: 7000,
        activeView: '',
        eventType: SCENARIO_EVENTS.OTA_INSTALL_NOW
    },
    {
        description: 'Ota Final',
        duration: 7000,
        activeView: '',
        eventType: SCENARIO_EVENTS.OTA_FINAL
    }
];

export var entireScenario: ScenarioEvent[] = [];
entireScenario.push({
    description: 'Switch to Maps',
    duration: 2000,
    activeView: APPS.MAPS,
    eventType: SCENARIO_EVENTS.TRIGGER_VIEW_CHANGE
});
entireScenario = entireScenario.concat(mapsRerouteScenario);
entireScenario.push({
    description: 'Switch to Music',
    duration: 2000,
    activeView: APPS.MUSIC,
    eventType: SCENARIO_EVENTS.TRIGGER_VIEW_CHANGE
});
entireScenario = entireScenario.concat(musicDownloadScenario);
entireScenario.push({
    description: 'Switch to OTA',
    duration: 2000,
    activeView: APPS.OTA,
    eventType: SCENARIO_EVENTS.TRIGGER_VIEW_CHANGE
});
entireScenario = entireScenario.concat(otaTonightScenario);
entireScenario.push({
    description: 'Loop Entire Scenario Autoplay',
    duration: 1,
    activeView: '',
    eventType: SCENARIO_EVENTS.LOOP_ENTIRE_AUTOPLAY
});
