import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import './Toast.scss';

const Toast = ({
    icon,
    descriptionBefore,
    title,
    descriptionAfter,
    additionalContent,
    buttonText,
    onPrimaryAction,
    dismissText,
    onDismiss,
    isInProgress,
    progressDuration,
    progressText
}) => {
    const [progress, setProgress] = useState(15); // Start w/ 15: hack to start w/ border-radius
    const maxProgress = 100;

    const variants = {
        hidden: {
            // Height is 30 rem but content is 25 rem to ensure background for overshoot during animation
            top: '-40rem'
        },
        visible: {
            // Height is 30 rem but content is 25 rem to ensure background for overshoot during animation
            top: '-10rem'
        }
    };

    useEffect(() => {
        var timer = undefined;
        if (isInProgress && progress < maxProgress) {
            timer = setTimeout(() => {
                // Because this changes state, effect will be run again
                // Therefore no need to call it recursively
                setProgress(progress + 1);
            }, (progressDuration - 1000) / 85); // Subtract 1000ms from progress duration to finish before disappearing
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isInProgress, progressDuration, progress, setProgress]);

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            transition={{ type: 'tween', ease: 'anticipate', duration: 0.5 }}
            exit="hidden"
            className="ToastContainer"
        >
            <div className="ContentContainer">
                <div className="IconContainer">
                    <img src={icon} alt="" />
                </div>
                <div className="MetadataContainer">
                    <p>
                        <span className="Warning">{descriptionBefore}</span>
                    </p>
                    <h2>{title}</h2>
                    <p>
                        <span className="Suggestion">{descriptionAfter}</span>
                    </p>
                    {additionalContent}
                </div>
                <div className="ActionContainer">
                    {isInProgress ? (
                        <div className="ProgressContainer">
                            <progress value={progress} max={maxProgress} />
                            <span>
                                {progressText}: {progress}%
                            </span>
                        </div>
                    ) : (
                        <div className="ButtonContainer">
                            <button className="PrimaryButton" onClick={onPrimaryAction}>
                                {buttonText}
                            </button>
                            <button className="SecondaryButton" onClick={onDismiss}>
                                {dismissText}
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <hr />
        </motion.div>
    );
};

export default Toast;
