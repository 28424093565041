// React stuff
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Global App State
import { AppControllerInstance, AppEventTypes } from '../../utils/AppController';

// Scenarios
import { entireScenario, mapsRerouteScenario } from '../../utils/Scenarios';

// Enums
import { APPS, SCENARIO_EVENTS } from '../../utils/enums';

// Style
import './AppSwitcher.scss';
import mapsIcon from '../../assets/icons/location.svg';
import menuIcon from '../../assets/icons/menu.svg';
import musicIcon from '../../assets/icons/music.svg';
import otaIcon from '../../assets/icons/cloud.svg';

import { ReactComponent as FullscreenIcon } from '../../assets/icons/fullscreen.svg';
import { ReactComponent as FullscreenExitIcon } from '../../assets/icons/fullscreen-exit.svg';
import { ReactComponent as MuteIcon } from '../../assets/icons/mute.svg';
import { ReactComponent as UnmuteIcon } from '../../assets/icons/unmute.svg';
import { ReactComponent as AutoPlayIcon } from '../../assets/icons/autoplay.svg';
import { ReactComponent as AutoPauseIcon } from '../../assets/icons/autopause.svg';
import { ReactComponent as EricssonLogo } from '../../assets/icons/ericsson-minimal.svg';

const FullscreenToggleButton = ({ onClick, isFullscreen }) => {
    return (
        <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            className="FullscreenToggleButton"
            onClick={onClick}
        >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            <div>
                <span>Fullscreen • F</span>
                {/* <span className="Key">F</span> */}
            </div>
        </motion.button>
    );
};

const MuteToggleButton = ({ onClick, isMuted }) => {
    return (
        <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            className="MuteToggleButton"
            onClick={onClick}
        >
            {isMuted ? <UnmuteIcon /> : <MuteIcon />}
        </motion.button>
    );
};

const AutoPlayToggleButton = ({ onClick, isPlaying }) => {
    return (
        <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.75 }}
            exit={{ opacity: 0 }}
            className="AutoPlayToggleButton"
            onClick={onClick}
        >
            {isPlaying ? <AutoPauseIcon /> : <AutoPlayIcon />}
            <div>
                <span>Autoplay • A</span>
                {/* <span className="Key">A</span> */}
            </div>
        </motion.button>
    );
};

// Destructure props:
// Type = which type of AppSwitcherButton this is
// onClick = onClick event defined by user of component
const AppSwitcherButton = ({ children, type, onClick }) => {
    // Variants for app switcher button animation
    const appSwitcherButtonVariants = {
        hidden: {
            opacity: 0,
            bottom: '1.5rem',
            left: '50%'
        },
        visibleMaps: {
            opacity: 1,
            bottom: '12rem',
            left: '35%'
        },
        visibleMusic: {
            opacity: 1,
            bottom: '20rem',
            left: '50%'
        },
        visibleOTA: {
            opacity: 1,
            bottom: '12rem',
            left: '65%'
        }
    };

    return (
        <motion.button
            onClick={onClick}
            className={'AppSwitcherButton'}
            variants={appSwitcherButtonVariants}
            initial="hidden"
            // Grab right variant to transition to by concatenating type
            animate={'visible' + type}
            transition={{ type: 'tween', ease: 'anticipate', duration: 0.5 }}
            exit="hidden"
        >
            {children}
        </motion.button>
    );
};

const AppSwitcher = () => {
    // Get global react app state and store it in local state
    const [appState, setAppState] = useState(AppControllerInstance.getAppState());

    // Subscribe / unsubscribe to global app state updates
    useEffect(() => {
        const handleAppStateChanged = () => {
            setAppState(AppControllerInstance.getAppState());
        };
        AppControllerInstance.addListener(AppEventTypes.appState, handleAppStateChanged);

        // Component did unmount
        return () => AppControllerInstance.removeListener(AppEventTypes.appState, handleAppStateChanged);
    }, [setAppState]);

    const setAppSwitcherActive = (appSwitcherActive) => {
        // Set app switcher active in global state
        AppControllerInstance.setAppSwitcherActive(appSwitcherActive);
    };

    const setActiveApp = (activeApp) => {
        // Set active app in global state
        AppControllerInstance.setActiveApp(activeApp, true);
        // Close app switcher
        setAppSwitcherActive(false);
    };

    useEffect(() => {
        const multiplier = appState.isFullscreen ? '0.8vh' : '0.55vh';
        document.documentElement.style.fontSize = multiplier;
    }, [appState.isFullscreen]);

    // Effect to listen to 'f' keypress to switch between fullscreen and windowed
    // Effect that is dependent on isFullscreen state
    useEffect(() => {
        const handleKeydown = ({ key }) => {
            if (key === 'f') {
                AppControllerInstance.setIsFullscreen(!appState.isFullscreen);
            }
        };
        window.addEventListener('keydown', handleKeydown);

        // Cleanup
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [appState.isFullscreen]);

    return (
        <div className="AppSwitcher">
            <button
                className="AppSwitcherButton Menu"
                type="Menu"
                onClick={() => {
                    if (appState.currentScenarioEvent.eventType === SCENARIO_EVENTS.INITIAL_LOAD) {
                        AppControllerInstance.setScenarioEvent(mapsRerouteScenario[0]);
                    }
                    setAppSwitcherActive(!appState.appSwitcherActive);
                }}
            >
                <img src={menuIcon} alt="" />
            </button>
            {/* Animate on mount and unmount */}
            <AnimatePresence>
                {appState.appSwitcherActive && (
                    <>
                        {/* Key needs to be set for AnimatePresence */}
                        <AppSwitcherButton key={APPS.MUSIC} type={APPS.MUSIC} onClick={() => setActiveApp(APPS.MUSIC)}>
                            <img src={musicIcon} alt="" />
                        </AppSwitcherButton>
                        <AppSwitcherButton key={APPS.MAPS} type={APPS.MAPS} onClick={() => setActiveApp(APPS.MAPS)}>
                            <img src={mapsIcon} alt="" />
                        </AppSwitcherButton>
                        <AppSwitcherButton key={APPS.OTA} type={APPS.OTA} onClick={() => setActiveApp(APPS.OTA)}>
                            <img src={otaIcon} alt="" />
                        </AppSwitcherButton>
                        <motion.div className="Info" key="DemoInfo" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <EricssonLogo className="EricssonLogo" />
                            <h3>The Network Aware Car demonstrating Software over the Air Updates and Network Aware Infotainment.</h3>
                        </motion.div>
                        {appState.isFullscreen && (
                            <motion.button
                                key="TriggerScenarioExplanation"
                                className="TriggerScenarioExplanation"
                                onClick={() => setAppSwitcherActive(false)}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0.75 }}
                                exit={{ opacity: 0 }}
                            >
                                In fullscreen:
                                <br />
                                Tap here to start
                            </motion.button>
                        )}
                        <AutoPlayToggleButton
                            onClick={() => {
                                setAppSwitcherActive(!appState.appSwitcherActive);
                                if (!appState.scenarioIsRunning) {
                                    AppControllerInstance.startScenarioRunner(entireScenario);
                                } else {
                                    AppControllerInstance.stopScenarioRunner();
                                }
                            }}
                            isPlaying={appState.scenarioIsRunning}
                        />
                        <MuteToggleButton onClick={() => AppControllerInstance.setMute(!appState.isMuted)} isMuted={appState.isMuted} />
                        <FullscreenToggleButton
                            onClick={() => AppControllerInstance.setIsFullscreen(!appState.isFullscreen)}
                            isFullscreen={appState.isFullscreen}
                        />
                    </>
                )}
            </AnimatePresence>
        </div>
    );
};

export default AppSwitcher;
