// Custom enum for active apps
export const APPS = {
    MUSIC: 'Music',
    MAPS: 'Maps',
    OTA: 'OTA',
    NONE: 'None'
};

// Custom enum for scenario events
export const SCENARIO_EVENTS = {
    INITIAL_LOAD: 'Initial Load',
    TRIGGER_VIEW_CHANGE: 'Trigger View Change',
    MAPS_INITIAL: 'Maps Initial',
    MAPS_REROUTE_TOAST: 'Trigger Reroute Toast',
    MAPS_CHOOSING_REROUTE: 'Maps Choosing Reroute',
    MAPS_REROUTED: 'Maps Rerouted',
    MUSIC_INITIAL: 'Music Initial',
    MUSIC_DOWNLOAD_TOAST: 'Music Download Toast',
    MUSIC_DOWNLOADING: 'Music Downloading',
    MUSIC_DOWNLOADED: 'Music Downloaded',
    OTA_INITIAL: 'Ota Initial',
    OTA_SET_TIME: 'Ota Set Time',
    OTA_SCHEDULED_FINAL: 'Ota Scheduled Final',
    OTA_INSTALL_NOW: 'Ota Install Now',
    OTA_FINAL: 'Ota Final',
    LOOP_ENTIRE_AUTOPLAY: 'Loop Entire Autoplay'
};
